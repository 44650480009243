<template>
  <div>
    <a-card style="background-color: #ffffff; border-radius: 10px">
      <h1>Latest Collections</h1>
      <a-table :columns="tableColumn" :data-source="tableData" />
    </a-card>
  </div>
</template>
<script>
import { reactive, computed } from 'vue'
import { DateFormatter } from '@/helpers/DateFormatter'
export default {
  props: {
    collections: {
      type: Object,
      default: function () {
        return {}
      }
    }
  },

  setup(props) {
    let tableData = reactive([])
    tableData = computed(() => {
      const collec = props.collections.data
      let collecArr = collec.splice(0, 5)
      collecArr.forEach((collection) => {
        collection.createdAt = DateFormatter(collection.createdAt)
        collection.status = collection.published ? 'Live' : 'Pending Approval'
      })
      return collecArr
    })
    const tableColumn = [
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name'
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: '_id'
      },
      {
        title: 'Created Date',
        dataIndex: 'createdAt',
        key: 'createdAt'
      }
    ]

    return { tableColumn, tableData }
  }
  //     const
  // const
}
</script>
<style scoped>
h1 {
  font-size: 20px;
  text-align: center;
  font-weight: 500;
  margin: 10px 0 10px 0;
}
</style>
