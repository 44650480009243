<template>
  <a-spin :spinning="requestLoading">
    <div class="container">
      <div class="mb-10">
        <PublishDesignerCard :designer="designer" />
      </div>
      <a-row style="margin-bottom: 35px" :gutter="24">
        <a-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8" class="my-4">
          <Card title="Total Collections" :detail="totalCollections">
            <div class="circle">
              <PictureOutlined class="icons first" />
            </div>
          </Card>
        </a-col>
        <a-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8" class="my-4">
          <Card title="Total Looks" :detail="totalLooks">
            <div class="circle">
              <CarryOutOutlined class="icons second" />
            </div>
          </Card>
        </a-col>
        <a-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8" class="my-4">
          <Card title="Published Collections" :detail="totalPublished">
            <div class="circle">
              <ReconciliationOutlined class="icons third" />
            </div>
          </Card>
        </a-col>
        <a-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8" class="my-4">
          <Card title="Archived Collections" :detail="totalArchived">
            <div class="circle">
              <ReconciliationOutlined class="icons third" />
            </div>
          </Card>
        </a-col>
      </a-row>
      <a-row>
        <a-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12" class="table w-full"
          ><LookbookTable :collections="collections"
        /></a-col>
      </a-row>
    </div>
  </a-spin>
</template>

<script>
import { CarryOutOutlined } from '@ant-design/icons-vue'
import { PictureOutlined } from '@ant-design/icons-vue'
import { ReconciliationOutlined } from '@ant-design/icons-vue'
import Card from './components/Cards.vue'
import LookbookTable from './components/LookbookTable.vue'
import PublishDesignerCard from '@/views/Settings/Components/PublishDesignerCard'
import useLoadingState from '@/use/loading-state'
import { computed, reactive, toRefs, watchEffect } from 'vue'
import { useStore } from 'vuex'

export default {
  components: {
    Card,
    CarryOutOutlined,
    PictureOutlined,
    ReconciliationOutlined,
    LookbookTable,
    PublishDesignerCard
  },
  setup() {
    const { requestLoading } = useLoadingState()
    const store = useStore()
    const localState = reactive({
      currentUser: {},
      totalCollections: 0,
      totalLooks: 0,
      totalPublished: 0,
      totalArchived: 0
    })
    localState.currentUser = computed(
      () => store.getters['auth/GetCurrentUser']
    )
    store.dispatch('designer/FetchDesigner', {
      designerId: localState.currentUser._id
    })
    store.dispatch('collection/FetchCollections', {
      designerId: localState.currentUser._id
    })
    const designer = computed(() => store.getters['designer/GetDesigner'])
    const collections = computed(
      () => store.getters['collection/GetCollections']
    )
    localState.totalArchived = computed(() => {
      const archived = store.getters['collection/GetUnPublishedCollections']
      return archived.data.length
    })
    localState.totalPublished = computed(() => {
      const published = store.getters['collection/GetPublishedCollections']
      return published.data.length
    })
    watchEffect(() => {
      if (collections.value.data) {
        let looks = 0
        collections.value.data.forEach((collection) => {
          looks += collection.looks.length
        })
        localState.totalLooks = looks
        localState.totalCollections = collections.value.data.length
      }
    })
    return { ...toRefs(localState), requestLoading, collections, designer }
  }
}
</script>

<style lang="css" scoped>
.card {
  margin: 0px 20px 0px 20px;
}
@media screen and (max-width: 600px) {
  .card {
    margin: 10px 0 10px 0;
  }
  .container {
    max-width: fit-content;
  }
}
.table {
  margin: 0px 20px 0px 0px;
}
.icons {
  font-size: 25px;
  margin: 10px 0px 0px 12px;
  color: #fff;
}

.circle {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #8f0705;
  margin-top: 20px;
}
</style>
