<template>
  <a-card>
    <a-row type="flex" justify="space-between">
      <a-col>
        <p class="my-4">
          Let users view your profile, please make sure you have added all the
          neccesary business details &amp; make sure you have uploaded a
          collection.
        </p>
      </a-col>
      <a-col>
        <a-switch
          v-model:checked="published"
          @change="handleProfileToggle"
          class="my-4"
          :class="published ? 'toggle__success' : 'toggle__danger'"
        />
      </a-col>
    </a-row>
  </a-card>
</template>

<script>
import { useStore } from 'vuex'
import { computed } from 'vue'
export default {
  props: {
    designer: {
      type: Object,
      default: function () {
        return {}
      }
    }
  },
  setup(props) {
    const store = useStore()
    const published = computed(() => props.designer.published)
    const handleProfileToggle = () => {
      const payload = {
        designerId: props.designer._id
      }
      store.dispatch('designer/PublishDesignerProfile', payload)
    }
    return { handleProfileToggle, published }
  }
}
</script>

<style lang="scss" scoped></style>
