import moment from 'moment'

export function DateFormatter(dateString) {
  return moment(dateString).format('DD-MMM-YYYY')
}
export function getDaysBetweenDates(startDate, endDate) {
  const start = moment(startDate, 'DD-MMM-YYYY')
  const end = moment(endDate, 'DD-MMM-YYYY')
  return end.diff(start, 'days') + 1
}
