<template>
  <div>
    <div>
      <a-card style="background-color: #ffffff; border-radius: 10px">
        <a-row type="flex">
          <a-col :flex="5" class="table">
            <p class="title">{{ title }}</p>
            <p class="detail">{{ detail }}</p></a-col
          >
          <a-col :flex="2">
            <!-- <img :src="imageUrl" class="card-img"> -->
            <slot></slot>
          </a-col>
        </a-row>
      </a-card>
    </div>
  </div>
</template>
<script>
export default {
  props: ['title', 'detail', 'imageUrl']
}
</script>
<style scoped>
.title {
  font-size: 18px;
  font-weight: bolder;
  color: #4f4f4f;
}
.detail {
  font-size: 18px;
  font-weight: 600;
  color: #4f4f4f;
}
.card-img {
  width: 3rem;
}
</style>
